import { Box } from "@chakra-ui/layout"
import React from "react"
import ziptiPrivacyPolicy from "../images/ziptiPrivacyPolicy.pdf"

const PrivacyPage: React.FC = () => {
  return (
    <Box h="100vh" w="100vw">
      <embed src={ziptiPrivacyPolicy} width="100%" height="100%" />
    </Box>
  )
}

export default PrivacyPage
